export default async () => {
    /**
     * Modules
     */
    let careComponents = [];
    let csComponents = [];
    // Darmin Care
    if (process.env.VUE_APP_DARMIN_CARE) {
        careComponents = await import('./modules/Care/CareComponents');
    }
    if (process.env.VUE_APP_DARMIN_CS) {
        csComponents = await import('./modules/CustomerService/CsComponents');
    }
    return {
        components: [...careComponents.default, ...csComponents.default],

        getComponent(title) {
            return this.components.find((obj) => obj.title === title);
        },
    };
};
